body {
    overflow-x: hidden;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.messageContainer {
  width: 100%;
  margin-top: 60px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: -50px;
  padding-right: 50px;
  padding-bottom: 75px;
}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Aovel Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Aovel Sans Regular'), url('static/fonts/ASansreg.woff') format('woff');
}


@font-face {
  font-family: 'Aovel Sans Light';
  font-style: normal;
  font-weight: normal;
  src: local('Aovel Sans Light'), url('static/fonts/ASans.woff') format('woff');
}


@font-face {
  font-family: 'Aovel Sans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Aovel Sans Bold'), url('static/fonts/ASansBOLD.woff') format('woff');
}


@font-face {
  font-family: 'Aovel Sans Black';
  font-style: normal;
  font-weight: normal;
  src: local('Aovel Sans Black'), url('static/fonts/ASansBlack.woff') format('woff');
}
